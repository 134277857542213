<template>
  <v-dialog
    v-model='dialog'
    persistent
    max-width='420'
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list>
        <v-list-item class='text-end'>
          <v-list-item-title>
            <v-list-item-subtitle class='d-block'>
              <p style='font-size: 14px' class='ma-0 pa-0 mb-1'>
                <strong>{{ $t('reports.titles.lastUpdate') }}:</strong><br>
                {{ parseDate(info.lastUpdate) }}
              </p>
              <p style='font-size: 14px' class='ma-0 pa-0'><strong>{{ $t('reports.titles.penddingRecord') }}:</strong> {{ info.changesPendants }} </p>
            </v-list-item-subtitle>
          </v-list-item-title>
          <v-list-item-action class='mx-5'>
            <v-btn
              :elevation='0'
              fab
              icon
              x-small
              @click='showConfigStock()'
            >
              <v-icon color="neuter">
                mdi-cog
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
    <v-card>
      <v-card-title class='text-h5'>
        {{ $t('reports.titles.reCalculateStock') }}
      </v-card-title>
      <v-card-text>
        <v-datetime-picker
          :show-date="true"
          :show-time="false"
          :locale="$i18n.locale"
          :text-field-props="textProps"
          :label="$t('records.fields.date')"
          :restrict-date="true"
          :restrict-year="2018"
          color="secondary"
          v-model="computedDate"
          date-format="dd / MM / yyyy"
          :disabled="isSync"
        />
        <v-btn :loading="isSync" class="mb-4" elevation="0" color="secondary" block :disabled="!date" @click="setAndRecalculate()">
          {{ $t('reports.titles.setAndReCalculate') }}
        </v-btn>

        <v-alert class="mt-2"
                 border="left"
                 color="orange"
                 dense
                 text
                 type="warning"
                 icon="mdi-alert-outline"
                 v-if="showAlert"
        >
          {{$t('reports.titles.pendingRecalcStock')}}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click='closeDialog'
          class="ma-2"
          :disabled="isSync"
        >
          {{ $t('general.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import moment from 'moment'

  export default {
    name: 'ReCalculateGlobalStock',

    data: () => ({
      profile: null,
      dialog: false,
      isSync: false,
      canUpdate: true,
      textProps: {
        appendIcon: 'mdi-calendar',
        color: 'secondary',
        small: true,
        flat: true,
        solo: true,
        backgroundColor: '#EAEAEA80',
      },
      date: new Date(),
      showAlert: false
    }),

    computed: {
      ...mapGetters({
        info: 'reports/reCalculateInfo'
      }),
      computedDate: {
        get() {
          return this.date ? new Date(this.date) : new Date()
        },
        set(data) {
          this.date = data ? new Date(data) : null
        },
      },
    },

    methods: {
      ...mapActions({
        setReCalculatedStockData: 'reports/setReCalculatedStockData',
        setDateAndReCalculate: 'reports/setDateAndReCalculate',
        getDateNumberOfPendants: 'reports/getDateNumberOfPendants',
        checkCanProcess: 'reports/checkCanProcess',
        fetchStockDate: 'reports/fetchStockDate'
      }),
      async setAndRecalculate() {
        this.isSync = true
        await this.setDateAndReCalculate([this.$toast, {
          dateToStart: this.date
        }]).finally(() => {
          this.isSync = false
        })
      },
      async showConfigStock() {
        this.dialog = true
        this.isSync = true

        await this.fetchStockDate([this.$toast]).then(res => {
          if (res.dateToStart) {
            this.date = new Date(res.dateToStart)
          }
        }).finally(() => {
          this.isSync = false
        })
      },
      closeDialog() {
        this.dialog = false
        this.showAlert = false
      },
      parseDate(date) {
        return moment(new Date(date)).format('DD/MM/YYYY')
      },
    },
  }
</script>

<style scoped>
</style>